import React from 'react';
import { useParams, Link } from 'react-router-dom';
const styles = {
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6'
  },
  quote: {
    margin: '2rem 0',
    padding: '1.5rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    border: '1px solid #e9ecef'
  },
  quoteText: {
    fontFamily: 'Times New Roman, serif',
    marginBottom: '1rem',
    color: '#343a40'
  },
  quoteFooter: {
    fontStyle: 'italic',
    color: '#6c757d'
  },
  projectsSection: {
    marginTop: '2rem'
  },
  projectCard: {
    marginBottom: '1.5rem',
    border: '1px solid #e9ecef',
    borderRadius: '8px',
    padding: '1.5rem',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '1rem'
  },
  projectTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#212529',
    margin: '0'
  },
  period: {
    fontSize: '0.875rem',
    color: '#6c757d'
  },
  description: {
    marginBottom: '1rem',
    color: '#495057'
  },
  sectionTitle: {
    fontSize: '0.875rem',
    fontWeight: '600',
    color: '#495057',
    marginBottom: '0.5rem'
  },
  container: {
    height: '100vh',
    overflowY: 'auto',
    padding: '2rem',
  },
  badgeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    marginBottom: '1rem'
  },
  badge: {
    padding: '0.25rem 0.75rem',
    borderRadius: '16px',
    fontSize: '0.875rem'
  },
  fieldBadge: {
    backgroundColor: '#e7f5ff',
    color: '#1864ab'
  },
  techBadge: {
    backgroundColor: '#f8f9fa',
    color: '#495057',
    border: '1px solid #dee2e6'
  },
  outcomesList: {
    listStyle: 'disc',
    paddingLeft: '1.25rem',
    marginBottom: '1rem',
    color: '#495057'
  },
  sectionHeader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: '2rem 0 1.5rem'
  },
  interestsList: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    marginBottom: '2rem'
  },
  interestItem: {
    padding: '0.5rem 1rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    fontSize: '0.9rem',
    color: '#495057',
    border: '1px solid #e9ecef'
  }
};
const ResearchProject = ({ title, period, description, fields, outcomes, technologies }) => (
  <div style={styles.projectCard}>
    <div style={styles.cardHeader}>
      <h3 style={styles.projectTitle}>{title}</h3>
      <span style={styles.period}>{period}</span>
    </div>
    <p style={styles.description}>{description}</p>
    
    <div style={{ marginBottom: '1rem' }}>
      <div style={styles.sectionTitle}>Fields of Study:</div>
      <div style={styles.badgeContainer}>
        {fields.map((field) => (
          <span key={field} style={{ ...styles.badge, ...styles.fieldBadge }}>
            {field}
          </span>
        ))}
      </div>
    </div>
    
    <div style={{ marginBottom: '1rem' }}>
      <div style={styles.sectionTitle}>Key Outcomes:</div>
      <ul style={styles.outcomesList}>
        {outcomes.map((outcome, index) => (
          <li key={index}>{outcome}</li>
        ))}
      </ul>
    </div>
    
    <div>
      <div style={styles.sectionTitle}>Technologies & Methods:</div>
      <div style={styles.badgeContainer}>
        {technologies.map((tech) => (
          <span key={tech} style={{ ...styles.badge, ...styles.techBadge }}>
            {tech}
          </span>
        ))}
      </div>
    </div>
  </div>
);

const Research = ({ isChaos }) => {
  const researchInterests = [
    "Quantum Computing",
    "Simulated Annealling",
    "Electromagnetic Theory",
    "Nonlinear Optics",
    "Hardware Accelerators for Machine Learning",
    "Molecular Spectroscopy",
    "Realtime Medicine",
    "Cyrogenic CMOS",
    "Computer Architecture",
    "Photonics",
    "MEMs",
    "Computer Vision",
    "Clean Energy and Distributed Grids",
    "3D Reconstruction",
  ];

  const projects = [
    {
      title: "Cross-Domain Data Visualization Platform",
      period: "2023",
      description: "Developed an innovative approach to financial data visualization that combines real-time analytics with intuitive user interfaces, demonstrating the power of interdisciplinary thinking between finance, design, and computer science.",
      fields: ["Financial Technology", "Data Visualization", "UX Design"],
      outcomes: [
        "Reduced data interpretation time by 40%",
        "Implemented real-time updates with sub-second latency",
        "Created customizable dashboard templates adopted by multiple teams"
      ],
      technologies: ["React", "D3.js", "WebSocket", "TypeScript", "Python"]
    },
    {
      title: "Collaborative Project Management System",
      period: "2022",
      description: "Applied principles from cognitive psychology and organizational behavior to design a more intuitive project management tool, focusing on how teams naturally organize and share information.",
      fields: ["Software Engineering", "Organizational Psychology", "Workflow Design"],
      outcomes: [
        "Increased team productivity by 25%",
        "Reduced onboarding time for new team members by 60%",
        "Implemented AI-assisted task prioritization"
      ],
      technologies: ["Next.js", "PostgreSQL", "Machine Learning", "Node.js"]
    },
    {
      title: "Social Network Analysis Platform",
      period: "2021",
      description: "Combined social psychology theories with modern mobile development practices to create an engaging social networking application focused on meaningful connections.",
      fields: ["Social Psychology", "Mobile Development", "Network Analysis"],
      outcomes: [
        "Achieved 85% user retention rate",
        "Developed novel connection-matching algorithm",
        "Published findings in HCI conference"
      ],
      technologies: ["React Native", "GraphQL", "AWS", "Firebase"]
    }
  ];


  return (
    <div className="container">
      <style>{`
        .container {
          max-width: 1200px;
          margin: 0 auto;
          margin-left: 250px;
          margin-bottom: 40px;
          padding: 40px;
          color: ${true ? '#E0E0E0' : '#333'};
          height: 100vh;
          overflow-y: scroll;
        }
      `}</style>
      <h1>Research</h1>
<p style={styles.paragraph}>
        I engaged in plenty of research as part of my Master's degree, my undergraduate degree and in high school. I enjoy research and inquiry. Solving an open ended puzzle is one of 
        the most satisfying feelings in my experience.
      </p>
      <p style={styles.paragraph}>
        I have made the conscious decision to prioritize breadth over depth, taking inspiration from John Locke's philosophy:
      </p>
      <div style={styles.quote}>
        <blockquote>
          <p style={styles.quoteText}>
            The acts of the mind, wherein it exerts its power over simple
            ideas, are chiefly these three: I. Combining several simple
            ideas into one compound one, and thus all complex ideas
            are made. II. The second is bringing two ideas, whether simple or complex, together, and setting them by one another
            so as to take a view of them at once, without uniting them
            into one, by which it gets all its ideas of relations. III. The
            third is separating them from all other ideas that accompany them in their real existence: this is called abstraction,
            and thus all its general ideas are made.
          </p>
          <footer style={styles.quoteFooter}>
            —John Locke, <cite>An Essay Concerning Human Understanding</cite> (1690) read in SICP
          </footer>
        </blockquote>
      </div>
      <p style={styles.paragraph}>
        I think the most impactful research is interdisciplinary, and I have found that the ability to abstract and combine ideas from different fields often leads to the most innovative solutions. 
      </p>
      <h2 style={styles.sectionHeader}>Research Interests</h2>
      <ul style={styles.interestsList}>
        {researchInterests.map((interest) => (
          <li key={interest} style={styles.interestItem}>
            {interest}
          </li>
        ))}
      </ul>
      <h2 style={styles.sectionHeader}>Featured Research Projects</h2>
      <div style={styles.projectsSection}>
        {projects.map((project, index) => (
          <ResearchProject key={index} {...project} />
        ))}
      </div>
    </div>
  );
};

export default Research;