import React from 'react';
import { Link } from 'react-router-dom';

const ProjectsPage = () => {
  const projects = [
    {
      id: 'project-2',
      title: 'Analytics Dashboard',
      shortDescription: 'Real-time data visualization dashboard with customizable widgets',
      tags: ['React', 'Node.js', 'D3.js'],
      image: '/api/placeholder/300/160'
    },
    {
      id: 'project-3',
      title: 'Machine Learning Pipeline',
      shortDescription: 'Automated ML pipeline for data processing and model training',
      tags: ['Python', 'TensorFlow', 'Docker'],
      image: '/api/placeholder/300/160'
    },
    {
      id: 'project-4',
      title: 'E-commerce Platform',
      shortDescription: 'Full-stack e-commerce solution with payment integration',
      tags: ['React', 'Node.js', 'Stripe'],
      image: '/api/placeholder/300/160'
    },
    {
      id: 'project-5',
      title: 'Social Media App',
      shortDescription: 'Mobile-first social networking application',
      tags: ['React Native', 'Firebase', 'Redux'],
      image: '/api/placeholder/300/160'
    },
    {
      id: 'project-6',
      title: 'Task Management System',
      shortDescription: 'Collaborative project management tool with real-time updates',
      tags: ['Vue.js', 'GraphQL', 'MongoDB'],
      image: '/api/placeholder/300/160'
    }
  ];

  return (
    <div className="projects-page">
      <style>{`
        .projects-page {
          max-width: 1200px;
          margin: 0 auto;
          margin-left: 250px;
          padding: 20px;
        }

        .projects-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 30px;
          padding: 20px 0;
        }

        .project-card {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 10px;
          padding: 20px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          cursor: pointer;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .project-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
          border-color: #007FFF;
        }

        .project-card h2 {
          color: #FFFFFF;
          font-size: 1.5em;
          margin-bottom: 10px;
        }

        .project-description {
          color: #E0E0E0;
          margin-bottom: 15px;
          line-height: 1.5;
        }

        .project-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
        }

        .tag {
          background: rgba(0, 127, 255, 0.1);
          color: #007FFF;
          padding: 4px 10px;
          border-radius: 15px;
          font-size: 0.9em;
        }

        .project-link {
          text-decoration: none;
          color: inherit;
        }

        .project-image {
          width: 100%;
          height: 160px;
          object-fit: cover;
          border-radius: 8px;
          margin-bottom: 15px;
        }
      `}</style>

      <h1>Projects</h1>
      <div className="projects-grid">
        {projects.map(project => (
          <Link 
            to={`/projects/${project.id}`} 
            key={project.id}
            className="project-link"
          >
            <div className="project-card">
              <img 
                src={project.image} 
                alt={project.title} 
                className="project-image"
              />
              <h2>{project.title}</h2>
              <p className="project-description">{project.shortDescription}</p>
              <div className="project-tags">
                {project.tags.map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProjectsPage;