import React from 'react';
import BubbleAnimation from '../components/BubbleAnimation';

const HomePage = ({ isChaos, setIsChaos }) => {
  return (
    <div className="home-page">
      <style>{`
        .home-page {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          margin
          color: #E0E0E0;
        }

        .content-wrapper {
          width: 100%;
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .dictionary-entry {
          text-align: left;
          max-width: 800px;
          padding: 20px;
          transform: translateY(-100%); /* Slight adjustment to account for visual weight */
        }

        .word {
          font-size: 4em;
          margin-bottom: 15px;
          color: #FFFFFF;
          font-family: serif;
        }

        .pronunciation {
          font-size: 1.4em;
          font-style: italic;
          color: #CCCCCC;
          margin-bottom: 20px;
          font-family: serif;
        }

        .part-of-speech {
          font-size: 1.2em;
          font-style: italic;
          color: #BBBBBB;
          margin-bottom: 15px;
          font-family: serif;
        }

        .definition {
          font-size: 1.4em;
          margin-left: 20px;
          line-height: 1.6;
          color: #FFFFFF;
          font-family: serif;
        }
      `}</style>

      {isChaos ? (
        <BubbleAnimation isAnimating={isChaos} setIsChaos={setIsChaos} />
      ) : (
        <div className="content-wrapper">
          <div className="dictionary-entry">
            <div className="word">ge·dan·ken·er</div>
            <div className="pronunciation">/ɡəˈdäNGkənər/</div>
            <div className="part-of-speech">noun</div>
            <div className="definition">
              1. One who conducts thought experiments; a person who explores ideas. <br></br>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;