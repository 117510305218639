import React from 'react';
import { useParams, Link } from 'react-router-dom';

const ProjectDetail = ({ isChaos }) => {
  const { projectId } = useParams();

  // This would typically come from an API or database
  const projectData = {
    'project-1': {
      title: 'Analytics Dashboard',
      description: `A comprehensive analytics dashboard that provides real-time data visualization 
        and insights. Built with React and D3.js, this project showcases advanced data visualization 
        techniques and real-time data processing capabilities.
        
        Key Features:
        • Real-time data updates
        • Customizable dashboard widgets
        • Interactive data visualization
        • Advanced filtering and sorting
        • Export capabilities`,
      fullTags: ['React', 'Node.js', 'D3.js', 'WebSocket', 'Redux', 'MongoDB'],
      images: ['/api/placeholder/800/400', '/api/placeholder/800/400'],
      links: {
        github: 'https://github.com/username/project',
        live: 'https://project-demo.com'
      },
      technologies: [
        {
          name: 'Frontend',
          items: ['React', 'D3.js', 'Redux', 'Styled Components']
        },
        {
          name: 'Backend',
          items: ['Node.js', 'Express', 'WebSocket', 'MongoDB']
        },
        {
          name: 'DevOps',
          items: ['Docker', 'AWS', 'GitHub Actions']
        }
      ]
    },

    'project-2': {
        title: 'Analytics Dashboard',
        description: `A comprehensive analytics dashboard that provides real-time data visualization 
          and insights. Built with React and D3.js, this project showcases advanced data visualization 
          techniques and real-time data processing capabilities.
          
          Key Features:
          • Real-time data updates
          • Customizable dashboard widgets
          • Interactive data visualization
          • Advanced filtering and sorting
          • Export capabilities`,
        fullTags: ['React', 'Node.js', 'D3.js', 'WebSocket', 'Redux', 'MongoDB'],
        images: ['/api/placeholder/800/400', '/api/placeholder/800/400'],
        links: {
          github: 'https://github.com/username/project',
          live: 'https://project-demo.com'
        },
        technologies: [
          {
            name: 'Frontend',
            items: ['React', 'D3.js', 'Redux', 'Styled Components']
          },
          {
            name: 'Backend',
            items: ['Node.js', 'Express', 'WebSocket', 'MongoDB']
          },
          {
            name: 'DevOps',
            items: ['Docker', 'AWS', 'GitHub Actions']
          }
        ]
      }

,
      'project-3': {
        title: 'Analytics Dashboard',
        description: `A comprehensive analytics dashboard that provides real-time data visualization 
          and insights. Built with React and D3.js, this project showcases advanced data visualization 
          techniques and real-time data processing capabilities.
          
          Key Features:
          • Real-time data updates
          • Customizable dashboard widgets
          • Interactive data visualization
          • Advanced filtering and sorting
          • Export capabilities`,
        fullTags: ['React', 'Node.js', 'D3.js', 'WebSocket', 'Redux', 'MongoDB'],
        images: ['/api/placeholder/800/400', '/api/placeholder/800/400'],
        links: {
          github: 'https://github.com/username/project',
          live: 'https://project-demo.com'
        },
        technologies: [
          {
            name: 'Frontend',
            items: ['React', 'D3.js', 'Redux', 'Styled Components']
          },
          {
            name: 'Backend',
            items: ['Node.js', 'Express', 'WebSocket', 'MongoDB']
          },
          {
            name: 'DevOps',
            items: ['Docker', 'AWS', 'GitHub Actions']
          }
        ]
      }

,
      'project-4': {
        title: 'Analytics Dashboard',
        description: `A comprehensive analytics dashboard that provides real-time data visualization 
          and insights. Built with React and D3.js, this project showcases advanced data visualization 
          techniques and real-time data processing capabilities.
          
          Key Features:
          • Real-time data updates
          • Customizable dashboard widgets
          • Interactive data visualization
          • Advanced filtering and sorting
          • Export capabilities`,
        fullTags: ['React', 'Node.js', 'D3.js', 'WebSocket', 'Redux', 'MongoDB'],
        images: ['/api/placeholder/800/400', '/api/placeholder/800/400'],
        links: {
          github: 'https://github.com/username/project',
          live: 'https://project-demo.com'
        },
        technologies: [
          {
            name: 'Frontend',
            items: ['React', 'D3.js', 'Redux', 'Styled Components']
          },
          {
            name: 'Backend',
            items: ['Node.js', 'Express', 'WebSocket', 'MongoDB']
          },
          {
            name: 'DevOps',
            items: ['Docker', 'AWS', 'GitHub Actions']
          }
        ]
      }

,
          'project-5': {
      title: 'Analytics Dashboard',
      description: `A comprehensive analytics dashboard that provides real-time data visualization 
        and insights. Built with React and D3.js, this project showcases advanced data visualization 
        techniques and real-time data processing capabilities.
        
        Key Features:
        • Real-time data updates
        • Customizable dashboard widgets
        • Interactive data visualization
        • Advanced filtering and sorting
        • Export capabilities`,
      fullTags: ['React', 'Node.js', 'D3.js', 'WebSocket', 'Redux', 'MongoDB'],
      images: ['/api/placeholder/800/400', '/api/placeholder/800/400'],
      links: {
        github: 'https://github.com/username/project',
        live: 'https://project-demo.com'
      },
      technologies: [
        {
          name: 'Frontend',
          items: ['React', 'D3.js', 'Redux', 'Styled Components']
        },
        {
          name: 'Backend',
          items: ['Node.js', 'Express', 'WebSocket', 'MongoDB']
        },
        {
          name: 'DevOps',
          items: ['Docker', 'AWS', 'GitHub Actions']
        }
      ]
    }

    ,
          'project-6': {
      title: 'Analytics Dashboard',
      description: `A comprehensive analytics dashboard that provides real-time data visualization 
        and insights. Built with React and D3.js, this project showcases advanced data visualization 
        techniques and real-time data processing capabilities.
        
        Key Features:
        • Real-time data updates
        • Customizable dashboard widgets
        • Interactive data visualization
        • Advanced filtering and sorting
        • Export capabilities`,
      fullTags: ['React', 'Node.js', 'D3.js', 'WebSocket', 'Redux', 'MongoDB'],
      images: ['/api/placeholder/800/400', '/api/placeholder/800/400'],
      links: {
        github: 'https://github.com/username/project',
        live: 'https://project-demo.com'
      },
      technologies: [
        {
          name: 'Frontend',
          items: ['React', 'D3.js', 'Redux', 'Styled Components']
        },
        {
          name: 'Backend',
          items: ['Node.js', 'Express', 'WebSocket', 'MongoDB']
        },
        {
          name: 'DevOps',
          items: ['Docker', 'AWS', 'GitHub Actions']
        }
      ]
    }
  };

  const project = projectData[projectId];

  if (!project) {
    return (
      <div className="project-not-found">
        <h1>Project Not Found</h1>
        <Link to="/projects">Back to Projects</Link>
      </div>
    );
  }

  return (
    <div className="project-detail">
      <style>{`
        .project-detail {
          max-width: 1200px;
          margin: 0 auto;
          margin-left: 250px;
          margin-bottom: 40px;
          padding: 40px;
          color: ${true ? '#E0E0E0' : '#333'};
          height: 100vh;
          overflow-y: scroll;
        }

        .back-link {
          display: inline-block;
          margin-bottom: 20px;
          color: #007FFF;
          text-decoration: none;
          font-size: 1.1em;
        }

        .back-link:hover {
          text-decoration: underline;
        }

        .project-header {
          margin-bottom: 40px;
        }

        .project-title {
          font-size: 2.5em;
          margin-bottom: 20px;
          color: ${true ? '#FFFFFF' : '#222'};
        }

        .project-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-bottom: 30px;
        }

        .tag {
          background: rgba(0, 127, 255, 0.1);
          color: #007FFF;
          padding: 6px 12px;
          border-radius: 15px;
          font-size: 0.9em;
        }

        .project-images {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
          margin-bottom: 40px;
        }

        .project-image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.05);
        }

        .project-description {
          font-size: 1.1em;
          line-height: 1.6;
          margin-bottom: 40px;
          white-space: pre-line;
        }

        .project-links {
          display: flex;
          gap: 20px;
          margin-bottom: 40px;
        }

        .project-link {
          padding: 10px 20px;
          background: #007FFF;
          color: white;
          text-decoration: none;
          border-radius: 5px;
          transition: background 0.3s ease;
        }

        .project-link:hover {
          background: #0066CC;
        }

        .technologies-section {
          margin-top: 40px;
          margin-bottom: 80px;
        }

        .tech-category {
          margin-bottom: 30px;
        }

        .tech-category h3 {
          font-size: 1.3em;
          margin-bottom: 15px;
          color: ${true ? '#FFFFFF' : '#222'};
        }

        .tech-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        .tech-item {
          background: rgba(255, 255, 255, 0.05);
          padding: 8px 15px;
          border-radius: 5px;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        @media (max-width: 768px) {
          .project-detail {
            margin-left: 0;
            padding: 20px;
          }

          .project-images {
            grid-template-columns: 1fr;
          }
        }
      `}</style>

      <Link to="/projects" className="back-link">← Back to Projects</Link>
      
      <div className="project-header">
        <h1 className="project-title">{project.title}</h1>
        <div className="project-tags">
          {project.fullTags.map((tag, index) => (
            <span key={index} className="tag">{tag}</span>
          ))}
        </div>
      </div>

      <div className="project-images">
        {project.images.map((image, index) => (
          <img 
            key={index}
            src={image} 
            alt={`${project.title} screenshot ${index + 1}`} 
            className="project-image"
          />
        ))}
      </div>

      <p className="project-description">{project.description}</p>

      <div className="project-links">
        <a href={project.links.github} target="_blank" rel="noopener noreferrer" className="project-link">
          View on GitHub
        </a>
        <a href={project.links.live} target="_blank" rel="noopener noreferrer" className="project-link">
          Live Demo
        </a>
      </div>

      <div className="technologies-section">
        <h2>Technologies Used</h2>
        {project.technologies.map((category, index) => (
          <div key={index} className="tech-category">
            <h3>{category.name}</h3>
            <div className="tech-list">
              {category.items.map((item, itemIndex) => (
                <span key={itemIndex} className="tech-item">{item}</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetail;