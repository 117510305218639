import React from 'react';
import PageTemplate from '../components/PageTemplate';

const CV = ({ isChaos }) => {
  // Ensure the PDF is located in the 'public' directory
  const pdfPath = `${process.env.PUBLIC_URL}/Resuume.pdf`;

  const content = (
    <div className="container">
      <style>{`
        .container {
          margin: 0 auto;
          color: ${isChaos ? '#E0E0E0' : '#333'};
          height: calc(100vh - 45px);
          overflow-y: auto;
          background: ${isChaos ? '#333' : '#E0E0E0'};
          display: flex;
          justify-content: center;
        }
        .pdf-container {
          width: 100%;
          height: 90%;
        }
        .pdf-viewer {
          width: 100%;
          height: calc(100vh - 100px);
          border: none;
        }
      `}</style>
      <div className="pdf-container">
        <iframe
          className="pdf-viewer"
          src={pdfPath}
          title="CV"
        >
          <p>
            Your browser doesn't support iframes.
            <a href={pdfPath}>Download the PDF here.</a>
          </p>
        </iframe>
      </div>
    </div>
  );

  return <PageTemplate title="CV" content={content} isChaos={isChaos} />;
};

export default CV;
