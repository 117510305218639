import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ isVisible }) => {
  const location = useLocation();
  const topPages = ['Projects', 'Research', 'CV'];
  const middlePages = ['Blog', 'Quotes', 'Books', 'Music', 'Pictures', 'Shows & TV'];
  const bottomPages = ['Leave Me a Note'];

  const isActivePage = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={`sidebar ${isVisible ? 'visible' : ''}`}>
      <style>{`
        .sidebar a {
          text-decoration: none;
          color: inherit;
        }

        .sidebar a.active {
          color: #007FFF;  /* Changed to bright blue */
          font-weight: bold;
        }
      `}</style>

      <nav>
        <ul>
          <li key={"home"}>
            <Link to="/" className={isActivePage('/') ? 'active' : ''}>
              Home
            </Link>
          </li>
          <hr />
          {topPages.map((page, index) => (
            <li key={index}>
              <Link 
                to={`/${page.toLowerCase().replace(/ /g, '-')}`}
                className={isActivePage(`/${page.toLowerCase().replace(/ /g, '-')}`) ? 'active' : ''}
              >
                {page}
              </Link>
            </li>
          ))}
          <hr />
          {middlePages.map((page, index) => (
            <li key={index}>
              <Link 
                to={`/${page.toLowerCase().replace(/ & /g, '-')}`}
                className={isActivePage(`/${page.toLowerCase().replace(/ & /g, '-')}`) ? 'active' : ''}
              >
                {page}
              </Link>
            </li>
          ))}
          <hr />
          {bottomPages.map((page, index) => (
            <li key={index}>
              <Link 
                to={`/${page.toLowerCase().replace(/ /g, '-')}`}
                className={isActivePage(`/${page.toLowerCase().replace(/ /g, '-')}`) ? 'active' : ''}
              >
                {page}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;