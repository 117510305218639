import React from 'react';
import PageTemplate from '../components/PageTemplate';

const Quotes = ({ isChaos }) => {
  const content = (
    <div style={{overflow:"auto"}}>
      <blockquote>"He never created a finished product. Finished products are for decadent minds. His was an evolving mechanism.." -  Asimov</blockquote>
      <blockquote>"You have to go over the line to see the line" - Simpkins</blockquote>
      <blockquote>"We shall not cease from exploration<br></br>
                    And the end of all our exploring<br></br>
                    Will be to arrive where we started<br></br>
                    And know the place for the first time." - T.S Eliot</blockquote>
      <blockquote>"I Forgorrrrrr" - Nada</blockquote>
      <blockquote>"They muddy the water, to make it seem deep" - Nietzsche</blockquote>
      <blockquote>"This is sooo dumb" - Abdallah</blockquote>
      <blockquote>"العلم درس وغرس" - A.A.</blockquote>
      <blockquote>"festina lente" - Augustus</blockquote>
      <blockquote>"The only thing we have to fear is fear itself" - FDR</blockquote>
      <blockquote>"You must bound the problem" - Das</blockquote>
      <blockquote>"من  طرق علي الباب وصل"</blockquote>
      <blockquote>"Leave him alone he is building his multi-dollar empire" - Birb</blockquote>
      <blockquote>"انهون" T.G.</blockquote>
      <blockquote>"Yes... all the dogs in the world came together and agreed that they would bark at you..." - D. Aronstein</blockquote>
      <blockquote>"Homo sum; humani nil a me alienum puto"</blockquote>
      <blockquote>"A society grows great when old men plant trees in whose shade they shall never sit" - Finance Guy </blockquote>
      <blockquote>"No ... I'm respectable, very respectful I tell you" - W. Eissa </blockquote>
      <blockquote>"If you haven't lived our lives, why do you feel free to judge it" -  A. Salem</blockquote>
      <blockquote>"carpe diem carpe noctem carpe omnia"</blockquote>
      <blockquote>"Why does everyone like boba so much?" Wyttenbach</blockquote>
      <blockquote>"But man is not made for defeat," he said. "A man can be destroyed but not defeated." - Hemingway</blockquote>
      <blockquote>"It is not the critic who counts; not the man who points out how the strong man stumbles, or where the doer of deeds could have done them better. The credit belongs to the man who is actually in the arena, whose face is marred by dust and sweat and blood; who strives valiantly; who errs, who comes short again and again, because there is no effort without error and shortcoming; but who does actually strive to do the deeds; who knows great enthusiasms, the great devotions; who spends himself in a worthy cause; who at the best knows in the end the triumph of high achievement, and who at the worst, if he fails, at least fails while daring greatly, so that his place shall never be with those cold and timid souls who neither know victory nor defeat." - Roosevelt</blockquote>
      <blockquote>"No man's knowledge can go beyond his experience" - John Locke</blockquote>
      <blockquote>"There's no genius without a touch of madness" - Aristotle</blockquote>
      <blockquote>"رفعت الأقلام وجفت الصحف"</blockquote>
    </div>
  );

  return <PageTemplate title="Quotes" content={content} isChaos={isChaos} />;
};

export default Quotes;